:root {
  --fontFamily: "Open Sans", sans-serif;
  --sm: 12px;
  --md: 14px;
  --lg: 16px;

  --regular: 400;
  --medium: 500;
  --semiBold: 600;
  --bold: 700;

  --primaryColor: #22b24c;
  --secondaryColor: #fff;
  --tertiaryColor: #006e98;
  --dark: rgba(30, 39, 46, 1);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--fontFamily);
  font-weight: 400;

  font-size: 16px;
}
.forms_items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;
}
.container {
  max-width: 1310px;
  margin: 0 auto;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-1 {
  width: 8.33%;
  float: left;
}
.col-md-2 {
  width: 16.66%;
  float: left;
}
.col-md-3 {
  width: 25%;
  float: left;
}
.col-md-4 {
  width: 33.33%;
  float: left;
}
.col-md-5 {
  width: 41.66%;
  float: left;
}
.col-md-6 {
  width: 50%;
  float: left;
}
.col-md-7 {
  width: 58.33%;
  float: left;
}
.col-md-8 {
  width: 66.66%;
  float: left;
}
.col-md-9 {
  width: 75%;
  float: left;
}
.col-md-10 {
  width: 83.33%;
  float: left;
}
.col-md-11 {
  width: 91.66%;
}
.col-md-12 {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 20px;
  margin-bottom: 2px;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #111;
  font-weight: 600;
  text-decoration: none;
}
.btnSubmit {
  font-weight: 600;
  width: 50%;
  color: #282726;
  background-color: #111;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 3px 6px !important;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #ffffff !important;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.caret.caret-up {
  border-top-width: 0;
  border-bottom: 4px solid #fff;
}
.input_items .col-md-3,
.input_items .col-md-9 {
  width: 100%;
  float: unset;
}
.btn-primary {
  background-color: #006e98;
  color: #fff;
}

.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

.row:after,
.form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

.required {
  color: #cb0000;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
table th,
table td {
  font-size: 14px !important;
}

.doctorCommentsArea > div {
  display: block !important;
  gap: 0;
  margin-top: 45px;
}
.doctorCommentsArea .col-md-3,
.doctorCommentsArea .col-md-9 {
  display: block;
  width: 100% !important;
  float: unset !important;
}
.yap-form .form-control {
  margin-top: 0;
}
.yap-form .form-group {
  margin-top: 10px;
  display: block;
  overflow: hidden;
}
.printButton {
  padding: 8px 36px !important;
}
.select2-container {
  width: 100% !important;
  margin-top: 7px !important;
}
.signatureSection {
  margin-top: 50px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  height: unset !important;
  width: 100% !important;
  margin-top: 0;
  position: absolute;
}

.patientTable {
  width: 100%;
  border: 1px solid #111;
  padding: 5px;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.patientTable td,
.patientTable th {
  padding: 5px;
  border: 1px solid #111;
}
.xray-of-title {
  margin: 35px 0;
}
@media print {
  .print-none {
    display: none;
  }
  .panel-body {
    border: none !important;
  }
}

.all-images {
  display: flex;
  gap: 30px;
  margin-bottom: 35px;
}
.all-images div {
  width: 25%;
}

.searchQuery {
  padding: 8px;
  font-size: 15px;
  margin: 10px 0;
}

.all-images div img {
  width: 100%;
}

#preview {
  display: flex;
  gap: 10px;
  margin: 10px;
  min-height: 75px;
}

.mobile-menu-toggle {
  display: none;
}

.select-dc-list li {
  display: inline-block;
  width: 141px;
  font-size: 14px;
  line-height: 38px;
}

.select-dc-list li label {
  font-size: 14px;
  line-height: 38px;
}
input[type="checkbox"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
@media (max-width: 767px) {
  .col-md-3,
  .col-md-9 {
    float: unset;
    width: 100%;
  }

  .login-form-2 {
    background-size: 100%;
    /* height: 100%; */
  }

  #main-menu .navbar-nav {
    display: none !important;
  }

  #main-menu .navbar-nav.open {
    display: inline-block !important;
  }

  .mobile-menu-toggle {
    display: block;
    padding: 7px;
    margin-top: 8px;
    border: navajowhite;
    font-size: 16px;
  }
}

.toster_custom_style {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
}
.Toastify__toast-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Toastify__toast-icon {
  width: 20px;
}
.patient_ids div {
  display: inline-block;
}
.patient_ids input {
  width: unset !important;
}

.image-200 {
  height: 150px;
  width: 150px;
}

.img-loader {
  width: 150px;
}
